@import "config";

.ups-page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .page-actions,
  [page-actions] {
    padding: 0;
  }
}

.refresh {
  .text {
    display: flex;

    @media #{$small-only} {
      display: none;
    }
  }
  .icon {
    cursor: pointer;
    border: 0 !important;
    display: none;
    @media #{$small-only} {
      display: block;
    }
  }
}

.recent-shipments {
  background-color: white;
  min-height: 100%;
  @extend .fill-height;

  .ups-page-header {
    @extend .page-center;
  }

  .off-hours-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: rem(11px) rem(44px) rem(11px) rem(21px);
    font-size: rem(14px);
    line-height: 1.36;
    color: $color-text-header;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(211, 211, 211, 0.5);

    // these colors are not preset colors.
    background-color: #fffbdb;
    border: solid 1px #fff6a9;
    border-left: solid 4px $color-notification-background;

    .material-icons {
      width: 20px;
      height: 20px;
      font-size: 20px;
      margin-right: rem(16px);
    }

    .message {
      flex: 1;
    }
  }

  .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &.declined {
      .material-icons {
        color: $ups-warning-red;
      }
    }

    .material-icons {
      font-size: 1rem;
      width: 1rem;
      height: 1rem;
      margin: 0 .25rem;
    }
  }

  .table-wrapper {
    position: relative;
  }
}

.sub-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  @extend .page-center;
  margin-bottom: 1em;
  @media #{$medium-up} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5em;
    margin-top: -3em;
  }
  .notice {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .mat-icon {
      margin-right: .25em;
    }
  }
}

::ng-deep {
  .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    &::before {
      content: '';
      background-color: transparent;
      border: solid thin transparent;
      border-radius: 50%;
      display: block;
      width: 1em;
      height: 1em;
      margin-right: .5em;
    }
    &.pending {
      &::before {
        background-color: $accent;
      }
    }
    &.printed,
    &.in-transit,
    &.delivered {
      &::before {
        background-color: $ups-grass;
      }
    }
    &.manifest,
    &.pickup-scan,
    &.bir {
      &::before {
        background-color: $ups-sand;
      }
    }
    &.voided,
    &.exception {
      &::before {
        background-color: $ups-error;
      }
    }
    &.reinstated {
      &::before {
        background-color: $ups-gray-light;
      }
    }
  }
}
