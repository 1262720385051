@import '../../../../assets/css/config.scss';
.investigation-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .mat-mdc-form-field {
        flex: 1;
    }

    @media #{$medium-up} {
        .mat-mdc-form-field {
            flex: 0 45%;
        }
        .mat-mdc-form-field:nth-of-type(odd){
            padding-right: 2em;
        }
        .mat-mdc-form-field:nth-of-type(even){
            padding-left: 2em;
        }
    }
}

.others{
    display: flex;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix{
        border: 1px solid $ups-stone;
    }
}