@import '../../../assets/css/config.scss';

.bir {
    .table-wrapper {
        position: relative;
    }
}

.grid-head,
.grid-rows {
    font-size: 11px;
}

.instruction {
    font-size: 13px;
    color: $ups-brown-light;
}

.bir-information {
    color: $ups-brown-light;
    padding-left: 2.5rem;
}

.bir-information,
.tracking-information {
    margin-bottom: 40px;
}

.bir-footer {
    margin-top: 10px;
    margin-left: 3em;
}

.txtbox {
    border-bottom: 0px !important;
    background-color: inherit;
}

.ship-date {
    color: $ups-bray;

    span {
        margin-right: 5px;
    }

    .mat-icon {
        vertical-align: text-top;
    }
}

.ship-date:hover {
    text-decoration: none;
}

.bir {
    color: $color-text-table-content;

    &.saved {
        color: $ups-green;
    }

    &.void {
        color: $ups-error;
    }
}

.dashboard-link {
    width: auto;
    display: inline-flex !important;
    text-decoration: none;
}

.dashboard-link:hover {
    text-decoration: none;
}

.material-icons {
    font-size: 18px;
}


.ups-page-header .page-title {
    @media #{$medium-up} {
        margin-left: 0;
    }
}

.mat-mdc-tooltip {
    //background-color: white;
    //color: $ups-black-4 !important;
    //border: 1px solid $ups-stone;
    //padding-top: 12px !important;
    //padding-left: 15px !important;
    //padding-right: 15px !important;
}