@import '../../../assets/css/config.scss';
.claims {
    min-width: $breakpoint-small;
    max-width: $breakpoint-xlarge;
    margin: 0 auto;

    .claim-sections {
        display: flex;
        //flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;

        // @media #{$medium-up} {
        //     flex-direction: row-reverse;
        //     justify-content: space-between;
        // }
        // .claim-form,
        // .claim-instructions {
        //     @media #{$medium-up} {
        //         width: 50%;
        //     }
        // }
        // .claim-form {
        //     @media #{$medium-up} {
        //         padding-right: 1em;
        //     }
        // }
        .claim-instructions {
            @media #{$medium-up} {
                flex: 1;
            }
        }
    }

    ul.steps {
        padding: 0;
        margin: 0;
        list-style-type: none;
        counter-reset: instructions-counter;

        li.step {
            margin-bottom: 2em;

            // &::before {
            //     content: 'STEP ' counter(instructions-counter);
            //     counter-increment: instructions-counter;
            //     display: block;
            //     font-weight: 700;
            // }
        }
    }

    .additional {
        background-color: $ups-background-color;
        color:$ups-bray;
        padding: 2em 0em;
        margin-bottom: 2em;

        p {
            padding: 0em 2.5em;
        }
    }

    .form-actions {
        justify-content: flex-start;
    }
}
